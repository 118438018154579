import { merge } from "lodash";
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
// material
import { Card, CardHeader, Box, Typography, Stack, alpha } from "@mui/material";
import { fNumber, findNextMultipleOf500 } from "../../../utils/formatNumber";
import { extractChartYearData } from '../../../utils/utils';

//
import { BaseOptionChart } from "../../charts";

// ----------------------------------------------------------------------

function AppConsultations(props) {
  const [consultationYaxis, setConsultationYaxis] = useState(0);
  const range = props.consultation.years_range
    ? props.consultation.years_range
    : [];

  useEffect(() => {
    if (props.consultation?.current_year) {
      var nextMultipleOf500 = findNextMultipleOf500(
        props.consultation.current_year[range[0]],
        props.consultation.current_year[range[1]]
      );

      if (nextMultipleOf500 % 1000 === 0) {
        setConsultationYaxis(nextMultipleOf500);
      } else {
        setConsultationYaxis(nextMultipleOf500 + 500);
      }
    }
  }, [props.consultation]);

  const chartData = extractChartYearData(props.consultation, props.lastUploadDate);
  let secondyear = new Date(new Date(props.lastUploadDate).setFullYear(new Date(props.lastUploadDate).getFullYear() - 1));
  const chartData2 = extractChartYearData(props.consultation, secondyear);

  const chartData3 = extractChartYearData(props.otherconsultation, props.lastUploadDate);
  const chartData4 = extractChartYearData(props.otherconsultation, secondyear);
  console.log(chartData);

  const CHART_DATA_Line = [
    {
      name: range[1] ? "your practice " + chartData.range : "",
      data: chartData ? chartData.yearData : [],
      fillColor: "#3E8397",
    },
    // {
    //   name: range[0] ? "your practice " + chartData2.range : "",
    //   data: chartData2 ? chartData2.yearData : [],
    //   fillColor: "#3E8397",
    // },
    // {
    //   name: range[1] ? "other practice " + chartData4.range : "",
    //   data: chartData4 ? chartData4.yearData : [],
    //   fillColor: alpha("#3E8397", 0.5),
    // },
    {
      name: range[0] ? "other practice " + chartData3.range : "",
      data: chartData3 ? chartData3.yearData : [],
      fillColor: alpha("#3E8397", 0.5),
    },
  ];

  const chartOptionsLine = merge(BaseOptionChart(), {
    tooltip: {
      shared: true,
      intersect: false,
      marker: { show: false },
      x: {
        show: false,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) =>
            " Number of consultations in " + `${seriesName}` + ":",
        },
      },
    },
    colors: ["#3E8397", "#5ca8bc", "#92c5d3", "#c9e2e9"],
    dataLabels: {
      enabled: false,
      style: {
        fontSize: "12px",
        colors: ["#000000"],
      },
      background: {
        enabled: false,
        color: "White",
      },
      formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        const seriesData = w.config.series[seriesIndex].data;
        const labelPoint = seriesData.filter(Boolean).length;
        if (dataPointIndex === labelPoint - 1) {
          return w.config.series[seriesIndex].name;
        } else {
          return "";
        }
      },
      offsetX: -9,
      offsetY: -3,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      borderColor: "#F5F5F5",
    },
    markers: {
      show: false,
      size: 0,
    },
    xaxis: {
      categories: chartData.months,
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: "#808080",
        },
        formatter: function (value) {
          return Math.round(value);  // Round the label value to integer
        }
      },
      min: 0,
      max: consultationYaxis,
      forceNiceScale: true,
    },
    legend: {
      show: true,
      position: "bottom",
      offsetY: 10,
      labels: {
        formatter: function (seriesName) {
          if (props.consultation.years_range.includes(seriesName)) {
            return seriesName;
          }
          return "";
        },
      },
    },
    chart: {
      events: {
        dataPointSelection: (event, chartContext, config) => {
          console.log(chartContext, config);
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "lighten",
            value: 0.15,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "darken",
            value: 0.35,
          },
        },
      },
    },
  });

  return (
    <Card sx={{ boxShadow: "none", mt: 4 }}>
      <Box dir="ltr">
        <ReactApexChart
          type="line"
          series={CHART_DATA_Line}
          options={chartOptionsLine}
          height={300}
        />
      </Box>
    </Card>
  );
}
import { connect } from "react-redux";
const mapState = (state) => ({
  consultation: state.overview.consultation,
  lastUploadDate: state.user.lastUpload,
  otherconsultation: state.antibioticscomparisons.consultation,
});

const mapDispatch = ({
  overview: { loadData },
  antibioticscomparisons: { getComparisonsAB },
}) => ({
  loadData: () => loadData(),
  getComparisonsAB: () => getComparisonsAB(),
});

export default connect(mapState, mapDispatch)(AppConsultations);
