import { useNavigate } from "react-router-dom";
import {
  isJwtExpired,
  timeToExpire,
} from "../components/authentication/checkJWT";
import { postData, getData } from "./auxilarFunctions";
import config from "../config.json";

let initial_state = {
  prescriptions: {
    othertotal: 14540,
    othermale: 1078,
    otherfemale: 879,
    otheractive_patients: 10956,
    otherconsultations: 23700,
    othertypes: [535, 1085, 593, 923, 929, 279, 964, 821, 1098, 730],
    other_week_days: [250, 135, 250, 70, 35],
    other_type_gms: 890,
    other_type_private: 7560,
  },
  generalABcomparison: {
    othertotal: 2130,
    otherperc_12_month: 34,
    otherperc_resistance_ab: 38,
  },
  consultationscomparison: {
    otherall: 9500,
    otherab: 4750,
  },
  prescriptionscomparison: {
    otherall: 7402,
    otherab: 5699,
  },

patients:{
  other_gender_patients: [1078, 879],
  other_gender_categories: [
    'Male', 
    'Female'
  ],
  other_age:[234,654,755,234,564,666,543],
  other_age_donut: [],
  other_gms_type_donut: [890,900],
  other_gms_type: [890,900]
  },
consultation:{
  current_year:[],
  previous_year: [],
  years_range: []
},
  abcomparison: {
    otherResistanceTotal:0,
    otherpractice_green: 13130,
    otherpractice_red: 4000,
    othertypes_prescribed: [
      {
        x: "Amoxicillin",
        y: 40,
        fillColor: "#26734D",
        strokeColor: "#26734D",
      },
      {
        x: "Doxycycline",
        y: 4,
        fillColor: "#26734D",
        strokeColor: "#26734D",
      },
      {
        x: "Ciprofloxacin",
        y: 10,
        fillColor: "#cc3311",
        strokeColor: "#cc3311",
      },
      {
        x: "Penicillin V",
        y: 5,
        fillColor: "#26734D",
        strokeColor: "#26734D",
      },
      {
        x: "Azithromyzin",
        y: 3,
        fillColor: "#cc3311",
        strokeColor: "#cc3311",
      },
      {
        x: "Co-amoxiclav",
        y: 3,
        fillColor: "#cc3311",
        strokeColor: "#cc3311",
      },
      {
        x: "Cephalexin",
        y: 35,
        fillColor: "#26734D",
        strokeColor: "#26734D",
      },
    ],
    othertypes_resistance: [
      {
        x: "Amoxicillin",
        y: 60,
        fillColor: "#26734D",
        strokeColor: "#26734D",
      },
      {
        x: "Doxycycline",
        y: 5,
        fillColor: "#26734D",
        strokeColor: "#26734D",
      },
      {
        x: "Ciprofloxacin",
        y: 12,
        fillColor: "#cc3311",
        strokeColor: "#cc3311",
      },
      {
        x: "Penicillin V",
        y: 23,
        fillColor: "#26734D",
        strokeColor: "#26734D",
      },
      {
        x: "Azithromyzin",
        y: 5,
        fillColor: "#cc3311",
        strokeColor: "#cc3311",
      },
      {
        x: "Co-amoxiclav",
        y: 38,
        fillColor: "#cc3311",
        strokeColor: "#cc3311",
      },
      {
        x: "Cephalexin",
        y: 8,
        fillColor: "#26734D",
        strokeColor: "#26734D",
      },
    ],
    othertypes_resistance_red: [
      {
        name: "Ciprofloxacin",
        data: [93, 100],
      },
      {
        name: "Azithromyzin",
        data: [29, 55],
      },
      {
        name: "Co-amoxiclav",
        data: [79, 15],
      },
    ],
    othertypes_red: [
      {
        name: "Ciprofloxacin",
        data: [93, 121],
      },
      {
        name: "Azithromyzin",
        data: [15, 45],
      },
      {
        name: "Co-amoxiclav",
        data: [59, 35],
      },
    ],

    othertypes_green: [
      {
        name: "Amoxicillin",
        data: [135, 56],
      },
      {
        name: "Doxycycline",
        data: [85, 110],
      },
      {
        name: "Penicillin V",
        data: [23, 0],
      },
      {
        name: "Cephalexin",
        data: [64, 86],
      },
    ],

    othertypes_resistance_green: [
      {
        name: "Amoxicillin",
        data: [100, 56],
      },
      {
        name: "Doxycycline",
        data: [85, 100],
      },
      {
        name: "Penicillin V",
        data: [23, 0],
      },
      {
        name: "Cephalexin",
        data: [64, 86],
      },
    ],
  },

  age: {
    other_age: [650, 912, 1901, 250, 10, 521, 1180],
    othergreen: [5653, 9133, 5445, 3176, 1070, 1465, 7300],
    otherred: [153, 33, 800, 300, 100, 850, 1080],
    categories: ["<1", "1-6", "7-17", "18-29", "30-49", "50-69", ">=70"],
  },

  hospitalarea: {
    green: [6653, 8133, 3445, 2176, 2070, 3465],
    red: [353, 133, 500, 700, 200, 650],
    categories: ["A", "B", "C", "D", "E", "F"],
  },

  patientnumber: {
    green: [809, 3309, 29899],
    red: [234, 118, 29899],
    categories: [
      "Same number of patients",
      "Same number of GMS patients",
      "Same number of private patients",
    ],
  },

  gpnumber: {
    green: [9321, 10133, 5445, 8176],
    red: [3053, 1033, 5000, 7500],
    categories: ["1", "2-3", "4-5", ">5"],
  },

  locationarea: {
    green: [17633, 29899],
    red: [15200, 32100],
    categories: ["Rural", "Urban"],
  },

  time: {
    othergreen_weekday: [553, 933, 664, 255, 460],
    otherred_weekday: [453, 233, 156, 187, 56],
    othergreen_duration: [60, 40, 15, 15, 5],
    otherred_duration: [15, 20, 55,15, 6],
    green_2021: [280, 290, 330, 150, 200, 133, 33, 67, 111, 187, 230, 255],
    red_2021: [120, 110, 140, 180, 170, 130, 13, 55, 55, 69, 112, 198],
    green_2020: [180, 190, 130, 50, 20, 33, 33, 67, 11, 87, 30, 55],
    red_2020: [20, 10, 14, 80, 70, 30, 13, 55, 55, 69, 12, 98],
    previousYear: [],
    greenRed: [],
  },

otherIcpc_consultation:{
  icpccode:[],
  icpcdescription: [],
  icpc_count: []
}
};

// count model
export const antibioticscomparisons = {
  state: initial_state,

  reducers: {
    updateCompare1(state, payload) {
      return {
        ...state,
        generalABcomparison: {
          ...state.generalABcomparison,
          othertotal: payload.ab_prescribed ? payload.ab_prescribed : 0,
          otherperc_12_month: payload.percentage_ab ? payload.percentage_ab : 0,
        },
      };
    },

    updateCompare2(state, payload) {
      const { count } = payload.abResistent || [];
      // Extract antibiotic names and their corresponding counts
      const data = count
        ? Object.entries(count).map(([name, count]) => ({ name, count }))
        : [];
      // Sort the data based on antibiotic name
      data.sort((a, b) => a.name.localeCompare(b.name));

      // Prepare data for rendering
      let resistanceData = [];
      let resistanceGreenData = [];
      let resistanceRedData = [];

      if (data.length >= 1) {
        resistanceData = data.slice(0, 10).map(({ name, count }, key) => {
          let fillColor, strokeColor;
          if (
            name === "Amoxicillin" ||
            name === "Trimethoprim" ||
            name === "Nitrofurantoin" ||
            name === "Fosfomycin"
          ) {
            fillColor = strokeColor = "#26734D";
            resistanceGreenData.push({
              x: name,
              y: count,
              fillColor,
              strokeColor,
            });
          } else if (
            name === "Co-Amoxyclav" ||
            name === "Cephalosporin" ||
            name === "Quinolones"
          ) {
            fillColor = strokeColor = "#cc3311";
            resistanceRedData.push({
              x: name,
              y: count,
              fillColor,
              strokeColor,
            });
          } else {
            fillColor = strokeColor = key % 2 === 0 ? "#26734D" : "#cc3311";
            key % 2 === 0
              ? resistanceGreenData.push({
                  x: name,
                  y: count,
                  fillColor,
                  strokeColor,
                })
              : resistanceRedData.push({
                  x: name,
                  y: count,
                  fillColor,
                  strokeColor,
                });
          }
          return {
            x: name,
            y: count,
            fillColor,
            strokeColor,
          };
        });
      } else {
        resistanceRedData.push({
          x: "",
          y: 0,
          fillColor: "#26734D",
          strokeColor: "#26734D",
        });
        resistanceGreenData.push({
          x: "",
          y: 0,
          fillColor: "#26734D",
          strokeColor: "#26734D",
        });
        resistanceData.push({
          x: "",
          y: 0,
          fillColor: "#26734D",
          strokeColor: "#26734D",
        });
      }

      return {
        ...state,
        generalABcomparison: {
          ...state.generalABcomparison,
          otherperc_resistance_ab: payload.abResistent.resistance ? payload.abResistent.resistance : 0,
        },
        abcomparison: {
          ...state.abcomparison,
          otherResistanceTotal: payload.abResistent ? ( payload.abResistent.resistanceTotal ? payload.abResistent.resistanceTotal : '0') : '0',
          othertypes_resistance_red: resistanceRedData,
          othertypes_resistance_green: resistanceGreenData,
        },
      };
    },

    updateCompare3(state, payload) {
      const typesPrescribed = [];
      const abPrescriptions = payload?.abPrescriptions1 || [];
      const prescribedGreenData = [];
      const prescribedRedData = [];

      if (abPrescriptions.length >= 1) {
        for (let i = 0; i < abPrescriptions.length && i < 10; i++) {
          if (
            abPrescriptions[i].level_4 &&
            abPrescriptions[i].level_4 !== undefined
          ) {
            let fillColor, strokeColor;
            if (
              ["Penicillin", "Trimethoprim", "Nitrofurantoin"].includes(
                abPrescriptions[i].level_4
              )
            ) {
              fillColor = "#26734D";
              strokeColor = "#26734D";
              prescribedGreenData.push({
                x: abPrescriptions[i].level_4,
                y:
                  (parseInt(abPrescriptions[i].total) /
                    state.generalABcomparison.othertotal) *
                  100,
                fillColor: fillColor,
                strokeColor: strokeColor,
              });
            } else if (
              [
                "Penicillins with beta-lactam inhibitors",
                "Cephalosporin",
                "Quinolones",
                "Tetracyclines",
                "Macrolides",
              ].includes(abPrescriptions[i].level_4)
            ) {
              fillColor = "#cc3311";
              strokeColor = "#cc3311";
              prescribedRedData.push({
                x: abPrescriptions[i].level_4,
                y:
                  (parseInt(abPrescriptions[i].total) /
                    state.generalABcomparison.othertotal) *
                  100,
                fillColor: fillColor,
                strokeColor: strokeColor,
              });
            }

            typesPrescribed.push({
              x: abPrescriptions[i].level_4,
              y:
                (parseInt(abPrescriptions[i].total) /
                  state.generalABcomparison.othertotal) *
                100,
              fillColor: fillColor,
              strokeColor: strokeColor,
            });
          }
        }
      } else {
        prescribedRedData.push({
          x: "",
          y: 0,
          fillColor: "#26734D",
          strokeColor: "#26734D",
        });
        prescribedGreenData.push({
          x: "",
          y: 0,
          fillColor: "#26734D",
          strokeColor: "#26734D",
        });
        typesPrescribed.push({
          x: "",
          y: 0,
          fillColor: "#26734D",
          strokeColor: "#26734D",
        });
      }

      return {
        ...state,
        abcomparison: {
          ...state.abcomparison,
          othertypes_red: prescribedRedData,
          othertypes_green: prescribedGreenData,
        },
      };
    },

    updateCompare4(state, payload) {
      let practiceGreenTotal = 0;
      let practiceRedTotal = 0;
      let abPrescriptions = payload.abPrescriptions2 ? payload.abPrescriptions2 : null;

      if(abPrescriptions != null){
        abPrescriptions[0].forEach((prescription) => {
          if (prescription.ab_classification === "Green") {
            practiceGreenTotal = parseInt(prescription.total);
          }
          if (prescription.ab_classification === "Red") {
            practiceRedTotal = parseInt(prescription.total);
          }
        });
      }

      return {
        ...state,
        abcomparison: {
          ...state.abcomparison,
          otherpractice_green: practiceGreenTotal,
          otherpractice_red: practiceRedTotal,
        },
      };
    },

    updateCompare5(state, payload) {
      return {
        ...state,
        consultationscomparison: {
          ...state.consultationscomparison,
          otherall: payload.consultationsPrescriptionsAB1.all_consultations ? payload.consultationsPrescriptionsAB1.all_consultations : 0,
          otherab: payload.consultationsPrescriptionsAB1.ab_consultations ? payload.consultationsPrescriptionsAB1.ab_consultations : 0,
        },
        prescriptionscomparison: {
          ...state.prescriptionscomparison,
          otherall: payload.consultationsPrescriptionsAB1.all_prescriptions ? payload.consultationsPrescriptionsAB1.all_prescriptions : 0,
          otherab: payload.consultationsPrescriptionsAB1.ab_prescriptions ? payload.consultationsPrescriptionsAB1.ab_prescriptions : 0,
        },
      };
    },

    updateCompare6(state, payload) {
      const zero = [0, 0, 0, 0, 0, 0, 0];

      return {
        ...state,
        age: {
          ...state.age,
          othergreen: payload.abPrescriptionsByAgeGroup1
            ? payload.abPrescriptionsByAgeGroup1.Green
              ? payload.abPrescriptionsByAgeGroup1.Green
              : zero
            : zero,
          otherred: payload.abPrescriptionsByAgeGroup1
            ? payload.abPrescriptionsByAgeGroup1.Red
              ? payload.abPrescriptionsByAgeGroup1.Red
              : zero
            : zero,
        },
      };
    },

    updateCompare7(state, payload) {
      const zero = [0, 0, 0, 0, 0];
      const zero1 = [0, 0, 0, 0, 0];

      return {
        ...state,
        time: {
          ...state.time,
          othergreen_weekday: payload.abPrescriptionsByDayofWeek1.Green ? payload.abPrescriptionsByDayofWeek1.Green : zero,
          otherred_weekday: payload.abPrescriptionsByDayofWeek1.Red ? payload.abPrescriptionsByDayofWeek1.Red : zero,
          othergreen_duration: payload.abPrescriptionsByDuration1.Green ? payload.abPrescriptionsByDuration1.Green : zero1,
          otherred_duration: payload.abPrescriptionsByDuration1.Red ? payload.abPrescriptionsByDuration1.Red : zero1,
        },
      };
    },

    updateComparisonsAB(state, payload) {
      console.log("State before updateComparisonsAB:", state);

      let indexes_hospitalareas_filters = [];
      initial_state.hospitalarea.categories.forEach((cat, index) => {
        if (payload.all_state.filters.hospitalarea.includes(cat)) {
          indexes_hospitalareas_filters.push(index);
        }
      });
      if (indexes_hospitalareas_filters.length == 0) {
        indexes_hospitalareas_filters = [0, 1, 2, 3, 4, 5];
      }
      let indexes_gpnumbers_filters = [];
      initial_state.gpnumber.categories.forEach((cat, index) => {
        if (payload.all_state.filters.gpnumber.includes(cat)) {
          indexes_gpnumbers_filters.push(index);
        }
      });
      if (indexes_gpnumbers_filters.length == 0) {
        indexes_gpnumbers_filters = [0, 1, 2, 3];
      }
      let indexes_patientnumbers_filters = [];
      initial_state.patientnumber.categories.forEach((cat, index) => {
        if (payload.all_state.filters.patientnumber.includes(cat)) {
          indexes_patientnumbers_filters.push(index);
        }
      });
      if (indexes_patientnumbers_filters.length == 0) {
        indexes_patientnumbers_filters = [0, 1, 2];
      }
      let indexes_locationareas_filters = [];
      initial_state.locationarea.categories.forEach((cat, index) => {
        if (payload.all_state.filters.locationarea.includes(cat)) {
          indexes_locationareas_filters.push(index);
        }
      });
      if (indexes_locationareas_filters.length == 0) {
        indexes_locationareas_filters = [0, 1];
      }
      let indexes_ages_filters = [];
      initial_state.age.categories.forEach((cat, index) => {
        if (payload.all_state.filters.age.includes(cat)) {
          indexes_ages_filters.push(index);
        }
      });

      if (indexes_ages_filters.length == 0) {
        indexes_ages_filters = [0, 1, 2, 3, 4, 5, 6];
      }

      console.log("State after updateComparisonsAB:", state);

      return {
        ...state,
        prescriptions: {
          othertotal: Math.round(
            initial_state.prescriptions.othertotal * payload.percentage
          ),
          othermale: Math.round(
            initial_state.prescriptions.othermale * payload.percentage
          ),
          otherfemale: Math.round(
            initial_state.prescriptions.otherfemale * payload.percentage
          ),
          otheractive_patients: Math.round(
            initial_state.prescriptions.otheractive_patients *
              payload.percentage
          ),
          otherconsultations: Math.round(
            initial_state.prescriptions.otherconsultations * payload.percentage
          ),
          othertypes: initial_state.prescriptions.othertypes.map((m) =>
            Math.round(m * payload.percentage)
          ),
          other_week_days: initial_state.prescriptions.other_week_days.map(
            (m) => Math.round(m * payload.percentage)
          ),
          other_type_gms: Math.round(
            initial_state.prescriptions.other_type_gms * payload.percentage
          ),
          other_type_private: Math.round(
            initial_state.prescriptions.other_type_private * payload.percentage
          ),
        },
        generalABcomparison: {
          othertotal: Math.round(
            initial_state.generalABcomparison.othertotal * payload.percentage
          ),
          otherperc_12_month: Math.round(
            initial_state.generalABcomparison.otherperc_12_month *
              payload.percentage
          ),
          otherperc_resistance_ab: Math.round(
            initial_state.generalABcomparison.otherperc_resistance_ab *
              payload.percentage
          ),
        },
        consultationscomparison: {
          otherall: Math.round(
            initial_state.consultationscomparison.otherall * payload.percentage
          ),
          otherab: Math.round(
            initial_state.consultationscomparison.otherab * payload.percentage
          ),
        },
        prescriptionscomparison: {
          otherall: Math.round(
            initial_state.prescriptionscomparison.otherall * payload.percentage
          ),
          otherab: Math.round(
            initial_state.prescriptionscomparison.otherab * payload.percentage
          ),
        },
        abcomparison: {
          otherpractice_green: Math.round(
            initial_state.abcomparison.otherpractice_green * payload.percentage
          ),
          otherpractice_red: Math.round(
            initial_state.abcomparison.otherpractice_red * payload.percentage
          ),
          othertypes_prescribed:
            initial_state.abcomparison.othertypes_prescribed.map((m) => {
              return { ...m, y: Math.round(m.y * payload.percentage) };
            }),
          othertypes_green: initial_state.abcomparison.othertypes_green.map(
            (m) => {
              return { ...m, y: Math.round(m.y * payload.percentage) };
            }
          ),
          othertypes_red: initial_state.abcomparison.othertypes_red.map((m) => {
            return { ...m, y: Math.round(m.y * payload.percentage) };
          }),
          othertypes_resistance:
            initial_state.abcomparison.othertypes_resistance.map((m) => {
              return { ...m, y: Math.round(m.y * payload.percentage) };
            }),
          othertypes_resistance_red:
            initial_state.abcomparison.othertypes_resistance_red.map((m) => {
              return { ...m, y: Math.round(m.y * payload.percentage) };
            }),
          othertypes_resistance_green:
            initial_state.abcomparison.othertypes_resistance_green.map((m) => {
              return { ...m, y: Math.round(m.y * payload.percentage) };
            }),
        },
        age: {
          other_age: initial_state.age.other_age
            .filter((cat, idx) => indexes_ages_filters.includes(idx))
            .map((cat) => Math.round(cat * payload.percentage * 0.95)),
          othergreen: initial_state.age.othergreen
            .filter((cat, idx) => indexes_ages_filters.includes(idx))
            .map((cat) => Math.round(cat * payload.percentage)),
          otherred: initial_state.age.otherred
            .filter((cat, idx) => indexes_ages_filters.includes(idx))
            .map((cat) => Math.round(cat * payload.percentage)),
          categories:
            payload.all_state.filters.age.length > 0
              ? payload.all_state.filters.age
              : initial_state.age.categories,
        },

        hospitalarea: {
          green: initial_state.hospitalarea.green
            .filter((cat, idx) => indexes_hospitalareas_filters.includes(idx))
            .map((cat) => Math.round(cat * payload.percentage)),
          red: initial_state.hospitalarea.red
            .filter((cat, idx) => indexes_hospitalareas_filters.includes(idx))
            .map((cat) => Math.round(cat * payload.percentage)),
          categories:
            payload.all_state.filters.hospitalarea.length > 0
              ? payload.all_state.filters.hospitalarea
              : initial_state.hospitalarea.categories,
        },
        patientnumber: {
          green: initial_state.patientnumber.green
            .filter((cat, idx) => indexes_patientnumbers_filters.includes(idx))
            .map((cat) => Math.round(cat * payload.percentage)),
          red: initial_state.patientnumber.red
            .filter((cat, idx) => indexes_patientnumbers_filters.includes(idx))
            .map((cat) => Math.round(cat * payload.percentage)),
          categories:
            payload.all_state.filters.patientnumber.length > 0
              ? payload.all_state.filters.patientnumber
              : initial_state.patientnumber.categories,
        },
        gpnumber: {
          green: initial_state.gpnumber.green
            .filter((cat, idx) => indexes_gpnumbers_filters.includes(idx))
            .map((cat) => Math.round(cat * payload.percentage)),
          red: initial_state.gpnumber.red
            .filter((cat, idx) => indexes_gpnumbers_filters.includes(idx))
            .map((cat) => Math.round(cat * payload.percentage)),
          categories:
            payload.all_state.filters.gpnumber.length > 0
              ? payload.all_state.filters.gpnumber
              : initial_state.gpnumber.categories,
        },
        locationarea: {
          green: initial_state.locationarea.green
            .filter((cat, idx) => indexes_locationareas_filters.includes(idx))
            .map((cat) => Math.round(cat * payload.percentage)),
          red: initial_state.locationarea.red
            .filter((cat, idx) => indexes_locationareas_filters.includes(idx))
            .map((cat) => Math.round(cat * payload.percentage)),
          categories:
            payload.all_state.filters.locationarea.length > 0
              ? payload.all_state.filters.locationarea
              : initial_state.locationarea.categories,
        },
        time: {
          othergreen_weekday: initial_state.time.othergreen_weekday.map((m) =>
            Math.round(m * payload.percentage)
          ),
          otherred_weekday: initial_state.time.otherred_weekday.map((m) =>
            Math.round(m * payload.percentage)
          ),
          othergreen_duration: initial_state.time.othergreen_duration.map((m) =>
            Math.round(m * payload.percentage)
          ),
          otherred_duration: initial_state.time.otherred_duration.map((m) =>
            Math.round(m * payload.percentage)
          ),
          green_2021: initial_state.time.green_2021.map((m) =>
            Math.round(m * payload.percentage)
          ),
          red_2021: initial_state.time.red_2021.map((m) =>
            Math.round(m * payload.percentage)
          ),
          green_2020: initial_state.time.green_2020.map((m) =>
            Math.round(m * payload.percentage)
          ),
          red_2020: initial_state.time.red_2020.map((m) =>
            Math.round(m * payload.percentage)
          ),
        },
      };
    },


    updatePoCompare1(state, payload) {
      return {
        ...state,
        prescriptions: {
          ...state.prescriptions,
          othertotal: payload.prescriptionFilterd ? payload.prescriptionFilterd : 0,
          otheractive_patients: payload.activePatientFiltered ? payload.activePatientFiltered : 0,
          otherconsultations: payload.counsultationFiltered ? payload.counsultationFiltered : 0,
        },
      };
    },

    updatePoCompare2(state, payload) {
      const typesPrescribed = [];
      const prescription_by_type = payload?.PrescriptionsByTypeFiltered.categories || [];
      const prescription_by_type_count = payload?.PrescriptionsByTypeFiltered.count || [];

      if(prescription_by_type.length>=1){
        for (let i = 0; i < prescription_by_type.length; i++) 
        {
          if(prescription_by_type_count[i] && prescription_by_type_count[i] !== undefined)
          {
            typesPrescribed.push({
              x: prescription_by_type[i],
              y: prescription_by_type_count[i],
            });
          }
        }
      }else{
        typesPrescribed.push({
          x:'',
          y: 0,
      })
      }

      return {
          ...state,
          prescriptions: {
            ...state.prescriptions,
            othertypes: typesPrescribed,
            other_week_days: payload.PrescriptionsByDayofWeekFiltered ? payload.PrescriptionsByDayofWeekFiltered.filteredCount : [],
            
          },
        }
    },

    updatePoCompare3(state, payload) {
      const patient_count_gender = [];
      const gender_categories = [];
      const patient_num = payload?.patientCountByGenderFiltered.patient_num || [];

      for (const item of patient_num) {
        if (item.gender && item.count) {
          patient_count_gender.push(parseInt(item.count));
          gender_categories.push(item.gender);
        }
    }

      return {
          ...state,
          patients:{
            ...state.patients,
            other_gender_patients: patient_count_gender, // payload[1]?.gender_patients,
            other_gender_categories: gender_categories,
          },
        }
    },

    updatePoCompare4(state, payload) {
      const patient_count_age = [];
      const patient_num = payload?.patientCountByAgeFiltered.age || [];
      let zero =0;
      let no18 =0;
      let no50 =0;
      let no70 =0;
      for (let i =0; i<patient_num.length ; i++) {
        if (patient_num[i] && i<3) {
          zero = zero + patient_num[i];
        } else if (patient_num[i] && i<5) {
          no18 = no18 + patient_num[i];
        } else if (patient_num[i] && i==5) {
          no50 = no50 + patient_num[i];
        } else {
          no70 = no70 + patient_num[i];
        }
    }
    patient_count_age.push(zero);
    patient_count_age.push(no18);
    patient_count_age.push(no50);
    patient_count_age.push(no70);

      return {
          ...state,
          patients:{
            ...state.patients,
            other_age:patient_num,
            other_age_donut:patient_count_age,
          },
        }
    },

    updatePoCompare5(state, payload) {

      const patient_gms_type = [2];
      const patient_gms_type_donut = [2];
      const patient_num = payload?.patientTypeCountFiltered.gms_type || [];

      for (const item of patient_num) {
        if (item.gmsstatus && item.gmsstatus=="1") {
          patient_gms_type[0]=item.count;
          patient_gms_type_donut[0]=parseInt(item.count);
          
        }
        if (item.gmsstatus && item.gmsstatus=="2") {
          patient_gms_type[1]=item.count;
          patient_gms_type_donut[1]=parseInt(item.count);
        }
      }

      return {
        ...state,
        patients:{
          ...state.patients,
          other_gms_type: patient_gms_type,
          other_gms_type_donut: patient_gms_type_donut
        },
      }
    },

    updatePoCompare6(state, payload) {
      return {
        ...state,
        consultation: {
          ...state.consultation,
          previous_year: payload.consultationsByYearFiltered.groupedYear,
          current_year: payload.consultationsByYearFiltered.groupedYear,
          years_range: payload.consultationsByYearFiltered.years
        }
      }
    },

    updatePoCompare7(state, payload) {
      const codedICPC = payload.coded_icpc_filtered;
      const icpcCodes = codedICPC?.map(item => ({
        code: item.icpccode,
        description: item.icpcdescription,
        count: item.icpc_count
      }));
      return {
        ...state,
        otherIcpc_consultation: {
          ...state.otherIcpc_consultation,
          icpccodes: icpcCodes?.map(code => code.code),
          icpc_descriptions: icpcCodes?.map(code => code.description),
          icpc_counts: icpcCodes?.map(code => code.count)
        }
      };
    },
  },
  effects: (dispatch) => ({
    async compare_1(payload, state) {
      try {
        const compare_1 = await postData(
          config.url + "/compare/compare1",
          {
            gps: state.filters.gps,
            healtharea: state.filters.healtharea,
            gms: state.filters.gms,
            age: state.filters.age,
            placeid: state.filters.locationarea,
            gender: state.filters.gender,
            start: state.filters.dateStart,
            end: state.filters.dateEnd,
          },
          state.user.access_token
        );

        return await this.updateCompare1(compare_1);
      } catch (error) {
        return error;
      }
    },

    async compare_2(payload, state) {
      try {
        const compare_2 = await postData(
          config.url + "/compare/compare2",
          {
            gps: state.filters.gps,
            healtharea: state.filters.healtharea,
            gms: state.filters.gms,
            age: state.filters.age,
            gender: state.filters.gender,
            placeid: state.filters.locationarea,
            start: state.filters.dateStart,
            end: state.filters.dateEnd,
          },
          state.user.access_token
        );

        return await this.updateCompare2(compare_2);
      } catch (error) {
        return error;
      }
    },

    async compare_3(payload, state) {
      try {
        const compare_3 = await postData(
          config.url + "/compare/compare3",
          {
            gps: state.filters.gps,
            healtharea: state.filters.healtharea,
            gms: state.filters.gms,
            age: state.filters.age,
            gender: state.filters.gender,
            placeid: state.filters.locationarea,
            start: state.filters.dateStart,
            end: state.filters.dateEnd,
          },
          state.user.access_token
        );

        return await this.updateCompare3(compare_3);
      } catch (error) {
        return error;
      }
    },

    async compare_4(payload, state) {
      try {
        const compare_4 = await postData(
          config.url + "/compare/compare4",
          {
            gps: state.filters.gps,
            healtharea: state.filters.healtharea,
            gms: state.filters.gms,
            age: state.filters.age,
            gender: state.filters.gender,
            placeid: state.filters.locationarea,
            start: payload.payload ? payload.payload.end1 : state.filters.dateStart,
            end: payload.payload ? payload.payload.start1 : state.filters.dateEnd,
          },
          state.user.access_token
        );

        return await this.updateCompare4(compare_4);
      } catch (error) {
        return error;
      }
    },

    async compare_5(payload, state) {
      try {
        const compare_5 = await postData(
          config.url + "/compare/compare5",
          {
            gps: state.filters.gps,
            healtharea: state.filters.healtharea,
            gms: state.filters.gms,
            age: state.filters.age,
            gender: state.filters.gender,
            placeid: state.filters.locationarea,
            start: state.filters.dateStart,
            end: state.filters.dateEnd,
          },
          state.user.access_token
        );

        return await this.updateCompare5(compare_5);
      } catch (error) {
        return error;
      }
    },

    async compare_7(payload, state) {
      try {
        const compare_7 = await postData(
          config.url + "/compare/compare7",
          {
            gps: state.filters.gps,
            healtharea: state.filters.healtharea,
            gms: state.filters.gms,
            age: state.filters.age,
            gender: state.filters.gender,
            placeid: state.filters.locationarea,
            start: state.filters.dateStart,
            end: state.filters.dateEnd,
          },
          state.user.access_token
        );

        return await this.updateCompare7(compare_7);
      } catch (error) {
        return error;
      }
    },

    async compare_6(payload, state) {
      try {
        let ageValues = state.antibiotics.age.categories;
        const ageRange = state.filters.age;
        let ageIndex = null;
        ageIndex =
          ageRange.length > 0
            ? ageRange.map((value) => {
                return ageValues.indexOf(value[0]);
              })
            : null;

        const compare_6 = await postData(
          config.url + "/compare/compare6",
          {
            gps: state.filters.gps,
            healtharea: state.filters.healtharea,
            gms: state.filters.gms,
            age: ageIndex,
            age2: state.filters.age,
            gender: state.filters.gender,
            placeid: state.filters.locationarea,
            start: state.filters.dateStart,
            end: state.filters.dateEnd,
          },
          state.user.access_token
        );

        return await this.updateCompare6(compare_6);
      } catch (error) {
        return error;
      }
    },

    async pocompare_1(payload, state) {
      try {
        let ageValues = state.antibiotics.age.categories;
        const ageRange = state.filters.age;
        let ageIndex = null;
        ageIndex =
          ageRange.length > 0
            ? ageRange.map((value) => {
                return ageValues.indexOf(value[0]);
              })
            : null;

        const compare_6 = await postData(
          config.url + "/compare/practicecompare1",
          {
            gps: state.filters.gps,
            healtharea: state.filters.healtharea,
            gms: state.filters.gms,
            age: state.filters.age,
            gender: state.filters.gender,
            placeid: state.filters.locationarea,
            start: state.filters.dateStart,
            end: state.filters.dateEnd,
          },
          state.user.access_token
        );

        return await this.updatePoCompare1(compare_6);
      } catch (error) {
        return error;
      }
    },

    async pocompare_2(payload, state) {
      try {
        let ageValues = state.antibiotics.age.categories;
        const ageRange = state.filters.age;
        let ageIndex = null;
        ageIndex =
          ageRange.length > 0
            ? ageRange.map((value) => {
                return ageValues.indexOf(value[0]);
              })
            : null;

        const compare_6 = await postData(
          config.url + "/compare/practicecompare2",
          {
            gps: state.filters.gps,
            healtharea: state.filters.healtharea,
            gms: state.filters.gms,
            age: state.filters.age,
            gender: state.filters.gender,
            placeid: state.filters.locationarea,
            start: state.filters.dateStart,
            end: state.filters.dateEnd,
          },
          state.user.access_token
        );

        return await this.updatePoCompare2(compare_6);
      } catch (error) {
        return error;
      }
    },

    async pocompare_3(payload, state) {
      try {
        let ageValues = state.antibiotics.age.categories;
        const ageRange = state.filters.age;
        let ageIndex = null;
        ageIndex =
          ageRange.length > 0
            ? ageRange.map((value) => {
                return ageValues.indexOf(value[0]);
              })
            : null;

        const compare_6 = await postData(
          config.url + "/compare/practicecompare3",
          {
            gps: state.filters.gps,
            healtharea: state.filters.healtharea,
            gms: state.filters.gms,
            age: state.filters.age,
            gender: state.filters.gender,
            placeid: state.filters.locationarea,
            start: state.filters.dateStart,
            end: state.filters.dateEnd,
          },
          state.user.access_token
        );

        return await this.updatePoCompare3(compare_6);
      } catch (error) {
        return error;
      }
    },

    async pocompare_4(payload, state) {
      try {
        let ageValues = state.antibiotics.age.categories;
        const ageRange = state.filters.age;
        let ageIndex = null;
        ageIndex =
          ageRange.length > 0
            ? ageRange.map((value) => {
                return ageValues.indexOf(value[0]);
              })
            : null;

        const compare_6 = await postData(
          config.url + "/compare/practicecompare4",
          {
            gps: state.filters.gps,
            healtharea: state.filters.healtharea,
            gms: state.filters.gms,
            age: ageIndex,
            age2: state.filters.age,
            gender: state.filters.gender,
            placeid: state.filters.locationarea,
            start: state.filters.dateStart,
            end: state.filters.dateEnd,
          },
          state.user.access_token
        );

        return await this.updatePoCompare4(compare_6);
      } catch (error) {
        return error;
      }
    },

    async pocompare_5(payload, state) {
      try {
        let ageValues = state.antibiotics.age.categories;
        const ageRange = state.filters.age;
        let ageIndex = null;
        ageIndex =
          ageRange.length > 0
            ? ageRange.map((value) => {
                return ageValues.indexOf(value[0]);
              })
            : null;

        const compare_6 = await postData(
          config.url + "/compare/practicecompare5",
          {
            gps: state.filters.gps,
            healtharea: state.filters.healtharea,
            gms: state.filters.gms,
            age: state.filters.age,
            gender: state.filters.gender,
            placeid: state.filters.locationarea,
            start: state.filters.dateStart,
            end: state.filters.dateEnd,
          },
          state.user.access_token
        );

        return await this.updatePoCompare5(compare_6);
      } catch (error) {
        return error;
      }
    },

    async pocompare_6(payload, state) {
      try {
        let ageValues = state.antibiotics.age.categories;
        const ageRange = state.filters.age;
        let ageIndex = null;
        ageIndex =
          ageRange.length > 0
            ? ageRange.map((value) => {
                return ageValues.indexOf(value[0]);
              })
            : null;

        const compare_6 = await postData(
          config.url + "/compare/practicecompare6",
          {
            gps: state.filters.gps,
            healtharea: state.filters.healtharea,
            gms: state.filters.gms,
            age: state.filters.age,
            gender: state.filters.gender,
            placeid: state.filters.locationarea,
            start: state.filters.dateStart,
            end: state.filters.dateEnd,
          },
          state.user.access_token
        );

        return await this.updatePoCompare6(compare_6);
      } catch (error) {
        return error;
      }
    },

    async pocompare_7(payload, state) {
      try {
        let ageValues = state.antibiotics.age.categories;
        const ageRange = state.filters.age;
        let ageIndex = null;
        ageIndex =
          ageRange.length > 0
            ? ageRange.map((value) => {
                return ageValues.indexOf(value[0]);
              })
            : null;

        const compare_6 = await postData(
          config.url + "/compare/practicecompare7",
          {
            gps: state.filters.gps,
            healtharea: state.filters.healtharea,
            gms: state.filters.gms,
            age: state.filters.age,
            gender: state.filters.gender,
            placeid: state.filters.locationarea,
            start: state.filters.dateStart,
            end: state.filters.dateEnd,
          },
          state.user.access_token
        );

        return await this.updatePoCompare7(compare_6);
      } catch (error) {
        return error;
      }
    },


    getComparisonsAB(payload, state) {
      console.log("state", state);
      let filters = [];
      let total_filters = 0;

      if (state.filters.hospitalarea.length > 0) {
        filters = filters + state.filters.hospitalarea.length;
        total_filters = total_filters + 6;
      }
      if (state.filters.age.length > 0) {
        filters = filters + state.filters.age.length;
        total_filters = total_filters + 7;
      }
      if (state.filters.gender.length > 0) {
        filters = filters + state.filters.gender.length;
        total_filters = total_filters + 3;
      }
      if (state.filters.gms.length > 0) {
        filters = filters + state.filters.gms.length;
        total_filters = total_filters + 2;
      }
      if (state.filters.gpnumber.length > 0) {
        filters = filters + state.filters.gpnumber.length;
        total_filters = total_filters + 4;
      }

      if (filters > 0 && total_filters > 0) {
        dispatch.antibioticscomparisons.updateComparisonsAB({
          percentage: filters / total_filters,
          all_state: state,
        });
      } else {
        dispatch.antibioticscomparisons.updateComparisonsAB({
          percentage: 1,
          all_state: state,
        });
      }
    },
  }),
};
