
export function checkYearDifference(tf, lastUpload) {
    const lastUploadDate = new Date(lastUpload); // Convert lastUpload to a Date object

    let previousDate;
    if (tf == 0) {
      previousDate = new Date(lastUploadDate.setMonth(lastUploadDate.getMonth() - 1)); // Subtract 1 month
    } else if (tf == 1) {
      previousDate = new Date(lastUploadDate.setMonth(lastUploadDate.getMonth() - 3)); // Subtract 3 months
    } else if (tf == 2) {
      previousDate = new Date(lastUploadDate.setFullYear(lastUploadDate.getFullYear() - 1)); // Subtract 12 months (1 year)
    } else {
      return "Invalid timeframe";
    }
  
    // Extract the years from both dates
    const lastUploadYear = new Date(lastUpload).getFullYear();
    let previousYear = new Date(new Date(lastUpload).setFullYear(lastUploadYear - 1));
    previousYear = new Date(previousYear).getFullYear();
  
    return {
      lastUploadYear,
      previousYear
    };
  }

 export function extractChartData(state, lastUploadDate) {
  const { now, mid, last } = state; 

  const lastUpload = new Date(lastUploadDate);
  const lastUploadYear = lastUpload.getFullYear(); // 2024
  const lastUploadMonth = lastUpload.getMonth(); // December = 11
  let yearStr2 = lastUploadYear.toString();

  let chartData = {
      months: [],
      yourPracticeGreen: [],
      yourPracticeRed: [],
      range:'',
  };

  if (yearStr2 == now){
    chartData.range = `${now} - ${mid}`;
  } else if (yearStr2 == mid){
    chartData.range = `${mid} - ${last}`;
  } 

  for (let i = 12; i >= 0; i--) {
      let date = new Date(lastUploadYear, lastUploadMonth - i);
      let yearStr = date.getFullYear().toString();
      let monthIndex = date.getMonth(); // 0-based index (Jan = 0, Dec = 11)
      let monthStr = date.toLocaleString('default', { month: 'short' });
      chartData.months.push(`${monthStr}`);

      // Retrieve Green and Red data
      let greenKey = `green_${yearStr}`;
      let redKey = `red_${yearStr}`;

      if (yearStr == now){
          greenKey = `green_2021`;
          redKey = `red_2021`;
      } else if (yearStr == mid){
          greenKey = `green_2020`;
          redKey = `red_2020`;
      } else if (yearStr == last){ 
          greenKey = `green_2019`;
          redKey = `red_2019`;
      } else {
        greenKey = `green_2021`;
        redKey = `red_2021`;
      }

      chartData.yourPracticeGreen.push(state[greenKey]?.[monthIndex] ?? 0);
      chartData.yourPracticeRed.push(state[redKey]?.[monthIndex] ?? 0);
  }

  return chartData;
}



export function extractChartYearData(state, lastUploadDate) {
  const { years_range } = state; 
  const now = years_range[0] ? years_range[0] : ''; 
  const mid = years_range[1] ? years_range[1] : ''; 
  const last = years_range[2] ? years_range[2] : ''; 

  const lastUpload = new Date(lastUploadDate);
  const lastUploadYear = lastUpload.getFullYear(); // 2024
  const lastUploadMonth = lastUpload.getMonth(); // December = 11
  let yearStr2 = lastUploadYear.toString();

  let chartData = {
      months: [],
      yearData: [],
      range:'',
  };

  if (yearStr2 == now){
    chartData.range = `${now} - ${mid}`;
  } else if (yearStr2 == mid){
    chartData.range = `${mid} - ${last}`;
  } 

  for (let i = 12; i >= 0; i--) {
      let date = new Date(lastUploadYear, lastUploadMonth - i);
      let yearStr = date.getFullYear().toString();
      let monthIndex = date.getMonth(); // 0-based index (Jan = 0, Dec = 11)
      let monthStr = date.toLocaleString('default', { month: 'short' });
      chartData.months.push(`${monthStr}`);

      // Retrieve Green and Red data

      chartData.yearData.push(state.current_year[yearStr]?.[monthIndex] ?? 0);
  }

  return chartData;
}
