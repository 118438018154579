import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { Card, CardHeader, Box, Typography, Stack, alpha } from "@mui/material";
import { fNumber, findNextMultipleOf500 } from "../../../utils/formatNumber";

//
import { BaseOptionChart } from "../../charts";
import React, { useState, useEffect } from "react";

// ----------------------------------------------------------------------

function AppLine(props) {
  const [weekdayGreenYaxis, setWeekdayGreenYaxis] = useState(null);
  const [durationGreenYaxis, setDurationGreenYaxis] = useState(null);
  const [weekdayRedYaxis, setWeekdayRedYaxis] = useState(null);
  const [durationRedYaxis, setDurationRedYaxis] = useState(null);

  useEffect(() => {
    var nextMultipleOf500 = findNextMultipleOf500(
      props.time.green_weekday,
      props.othertime.othergreen_weekday
    );
    setWeekdayGreenYaxis(nextMultipleOf500);

    nextMultipleOf500 = findNextMultipleOf500(
      props.time.red_weekday,
      props.othertime.otherred_weekday
    );
    setWeekdayRedYaxis(nextMultipleOf500);

    nextMultipleOf500 = findNextMultipleOf500(
      props.time.green_duration,
      props.othertime.othergreen_duration
    );
    setDurationGreenYaxis(nextMultipleOf500);

    nextMultipleOf500 = findNextMultipleOf500(
      props.time.red_duration,
      props.othertime.otherred_duration
    );
    setDurationRedYaxis(nextMultipleOf500);
  }, [props.time, props.othertime]);

  const CHART_DATA_Week_GREEN = [
    {
      name: ["Your practice"],
      data: props.time.green_weekday.slice(0, 5),
      color: "#26734D",
    },
    {
      name: ["Other practices"],
      data: props.othertime.othergreen_weekday.slice(0, 5),
      color: alpha("#26734D", 0.5),
    },
  ];

  const CHART_DATA_Week_RED = [
    {
      name: ["Your practice"],
      data: props.time.red_weekday.slice(0, 5),
      color: "#cc3311",
    },
    {
      name: ["Other practices"],
      data: props.othertime.otherred_weekday.slice(0, 5),
      color: alpha("#cc3311", 0.5),
    },
  ];

  const chartOptionsWeek = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: function (value, opts) {
          const total = opts.w.globals.seriesTotals[opts.seriesIndex];
          const percentage = ((value / total) * 100).toFixed(0);
          return `${percentage}%`;
        },

        title: {
          formatter: (seriesName) =>
            "Percentage of antibiotic prescribed on this day: ",
        },
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
        columnWidth: "70%",
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        return fNumber(val);
      },
      style: {
        colors: ["#708090"],
      },
      offsetY: -20,
    },

    yaxis: {
      show: false,
      min: 0,
      max: Math.max(weekdayGreenYaxis, weekdayRedYaxis),
      tickAmount: 4,
    },
    xaxis: {
      categories: ["Mon", "Tues", "Wed", "Thur", "Fri"],
    },
    stroke: {
      show: false,
    },
    legend: {
      show: true,
      position: "bottom",
    },
  });

  const CHART_DATA_Duration_GREEN = [
    {
      name: ["Your practice"],
      data: props.time.green_duration,
      color: "#26734D",
    },
    {
      name: ["Other practices"],
      data: props.othertime.othergreen_duration,
      color: alpha("#26734D", 0.5),
    },
  ];

  const CHART_DATA_Duration_RED = [
    {
      name: ["Your practice"],
      data: props.time.red_duration,
      color: "#cc3311",
    },
    {
      name: ["Other practices"],
      data: props.othertime.otherred_duration,
      color: alpha("#cc3311", 0.5),
    },
  ];

  const chartOptionsDuration = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: function (value, opts) {
          const total = opts.w.globals.seriesTotals[opts.seriesIndex];
          const percentage = ((value / total) * 100).toFixed(0);
          return `${percentage}%`;
        },

        title: {
          formatter: (seriesName) =>
            "Antibiotic prescriptions for this number of days:",
        },
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
        columnWidth: "90%",
      },
      chart: {
        id: "chart1",
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        return fNumber(val);
      },
      style: {
        colors: ["#708090"],
      },
      offsetY: -20,
    },
    xaxis: {
      categories: [
        '0-3 days',
        '4-7 days',
        '8-14 days',
        '15-30 days',
        ["More", "than", "30 days"],
      ],
      rotate: 0,
    },
    yaxis: {
      show: false,
      min: 0,

      max: Math.max(durationRedYaxis, durationGreenYaxis),
      tickAmount: 4,
    },
    fill: {
      colors: ["#339966"],
    },
    stroke: {
      show: false,
    },
    legend: {
      show: true,
      position: "bottom",
      brush: {
        target: "chart2",
        enabled: true,
      },
    },
    // strokeColor: ['transparent'],
  });

  return (
    <Card sx={{ boxShadow: "none", mt: 4 }}>
      <Typography variant="h4" color="#22577A">
        Prescriptions of green and red antibiotics over time
      </Typography>
      <br />
      <Typography variant="h6" color="#22577A">
        Per weekday
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-end"
        spacing={3}
        mt={10}
      >
        <Box sx={{ mx: 1, width: 600, height: 400 }} dir="ltr">
          <ReactApexChart
            type="bar"
            series={CHART_DATA_Week_GREEN}
            options={chartOptionsWeek}
            height={300}
          />
        </Box>
        <Box sx={{ mx: 1, width: 600, height: 400 }} dir="ltr">
          <ReactApexChart
            type="bar"
            series={CHART_DATA_Week_RED}
            options={chartOptionsWeek}
            height={300}
          />
        </Box>
      </Stack>
      <br />
      <br />
      <Typography variant="h6" color="#22577A">
        Duration
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-end"
        spacing={3}
        mt={10}
      >
        <Box sx={{ mx: 1, width: 600, height: 400 }} dir="ltr">
          <ReactApexChart
            type="bar"
            series={CHART_DATA_Duration_GREEN}
            options={chartOptionsDuration}
            height={364}
          />
        </Box>
        <Box sx={{ mx: 1, width: 600, height: 400 }} dir="ltr">
          <ReactApexChart
            type="bar"
            series={CHART_DATA_Duration_RED}
            options={chartOptionsDuration}
            height={364}
          />
        </Box>
      </Stack>
    </Card>
  );
}
import { connect } from "react-redux";

const mapState = (state) => ({
  time: state.antibiotics.time,
  othertime: state.antibioticscomparisons.time,
});

const mapDispatch = ({
  antibioticscomparisons: { getComparisonsAB },
  antibiotics: { getGeneralAB },
}) => ({
  getComparisonsAB: () => getComparisonsAB(),
  getGeneralAB: () => getGeneralAB(),
});

export default connect(mapState, mapDispatch)(AppLine);
